.sidebar {
  width: 300px;
  border-right: 1px solid #ddd;
  height: 100vh;
  overflow-y: auto;
}

.rce-citem-body--bottom-status span,
.rce-citem-body--bottom-status-icon {
  background-color: #43b8f5;
  height: 20px !important;
  width: 20px !important;
}
