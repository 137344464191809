@import "~react-image-gallery/styles/css/image-gallery.css";

.rce-container-clist {
overflow: hidden;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 88vh;
  width: 100%;
  position: relative;
}

.chat-container-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 88vh;
  width: 100%;
}

.chat-container-div span {
  font-weight: bold;
  font-size: 30px !important;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  width: 100%;
  /* width: calc(100% - 367px); */
  /* position: absolute;
  bottom:-10px; */
}

.reply-message {
  margin-bottom: 5px;
  border-left: 2px solid darkgray;
  padding-left: 10px;
  /* background-color: darkgray; */
  /* width: 50px; */
}
.clear-button{
  position: absolute;
  right:10px;
  top: 11px;
}
.reply-message svg {
  display: none;
}

.replying-to {
  padding: 5px;
  background-color: #f1f1f1;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
}
.attachment-preview {
  margin: 5px;
  display: inline-block;
}

.attachment-preview img {
  max-width: 100px;
  max-height: 100px;
}

.attachments-preview {
  display: flex;
  /* flex-wrap: wrap; */
  overflow: scroll;
  width: 100%;
  margin-bottom: 10px;
}

.rce-container-input {
  min-width: auto !important;
  width: 100%;
}

.chat_image_preview_sender {
  height: 255px;
  width: 260px;
  position: absolute;
  right: 23px;
  top: 5px;
  z-index: 1;
}

.chat_image_preview {
  height: 262px;
  width: 260px;
  position: absolute;
  left: 23px;
  top: 5px;
  z-index: 1;
}

.preview_text {
  position: absolute;
  bottom: 51px;
  right: 57px;
  color: white;
  font-size: 30px !important;
  font-weight: 700 !important;
}

.div1 {
  height: 120px;
  width: 125px;
  border-radius: 10px;
  margin: 5px 0px 0px 5px;
}

.div2 {
  background-color: #f7f8fa;
  border-radius: 10px;
  margin: 5px 5px 0px 5px;
  height: 120px;
  width: 115px;
}

.div3 {
  background-color: #f7f8fa;
  height: 120px;
  width: 125px;
  margin: 5px 0px 5px 5px;
  border-radius: 10px;
}

.div4 {
  border-radius: 10px;
  margin: 5px;
  height: 120px;
  width: 115px;
  background-color: #a3a4a5;
}
